import React, {Component} from 'react';
import {translate} from "../i18n/customI18nProvider";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { formatNumber } from '../util';

class EntranceCount extends Component {

    max_allowed_height = 750;
    page_height = 1600;

    componentDidMount() {
        const element = document.getElementById('entrance_count_chart');
        const element_print = document.getElementById('entrance_count_chart_print');
        if (element && element_print) {
            // console.log(element.offsetHeight);
            if (element.offsetHeight > this.max_allowed_height) {
                let times = Math.ceil(element.offsetHeight / this.page_height);
                element_print.style.marginTop = this.max_allowed_height + 100 + "px";
                element_print.style.height = (times * this.page_height) - 100 - ((times-1) * 100 ) + "px";
            } else if (element.offsetHeight <= this.max_allowed_height) {
                element_print.style.marginBottom = this.max_allowed_height - element.offsetHeight + 50 + "px";
            }
        }
    }

    render() {

        const { stats_clients, entrances } = this.props;

        let days = {};
        let result = [];
        let unusedActivatedClients = 0;
        let activeClients = 0;
        if(stats_clients) {
            days = stats_clients.days ? stats_clients.days : {};
            result = stats_clients.result ? stats_clients.result : [];
        }

        if ('unusedActivatedClients' in days) {
            unusedActivatedClients = days['unusedActivatedClients'] ? days['unusedActivatedClients'] : 0;
            delete days['unusedActivatedClients'];
        }

        if ('activeClients' in days) {
            activeClients = days['activeClients'] ? days['activeClients'] : 0;
            delete days['activeClients'];
        }

        const days_keys = Object.keys(days);
        const days_vals = Object.values(days);
        

        const row_data = [];
        const total = {
            "daywise_entrances" : 0,
            "daywise_people" : 0,
            "entrances" : 0
        };

        if (unusedActivatedClients) {
            row_data.push({
                daywise_entrances: unusedActivatedClients,
                date: translate('custom.unused_activated_clients'),
                daywise_people: 0,
                day: 0
            });
        }

        if (activeClients) {
            row_data.push({
                daywise_entrances: activeClients,
                date: translate('custom.active_clients'),
                daywise_people: 0,
                day: 0
            });
        }

        for(let i=0; i<days_keys.length; i++) {
            let obj = {
                daywise_entrances: days_vals[i],
                date: (new Date(days_keys[i])).toLocaleDateString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }),
                daywise_people: result[i] ? result[i] : 0,
                day: i+1
            };
            total["daywise_entrances"] += obj["daywise_entrances"];
            total["daywise_people"] += obj["daywise_people"];
            row_data.push(obj);
        }

        for(let i=0; i<entrances?.length; i++) {

            let obj = {
                entrance_name: entrances[i]._id.name,
                entrance_begin_date: entrances[i]._id.begin_date ? (new Date(entrances[i]._id.begin_date)).toLocaleDateString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) : null,
                entrances: entrances[i].total
            };

            if(row_data[i]) {
                Object.assign(row_data[i], obj);
            } else {
                row_data[i] = obj;
            }

            total["entrances"] += entrances[i].total;
        }


        if(stats_clients && row_data.length > 0) {

            let view = (
                <>
                    <h1 className={this.props.classes.titleTextStyle}>{translate('custom.entrance_count')}</h1>
                    <Box overflow="auto">
                        <Table style={{marginBottom: 20}} >
                            <TableBody className={this.props.classes.table_head} >
                                <TableRow>
                                    <TableCell className={this.props.classes.table_head} style={{width: '30%'}}></TableCell>
                                    <TableCell className={this.props.classes.table_head}>{translate('custom.date')}</TableCell>
                                    {/* <TableCell className={this.props.classes.table_head}></TableCell> */}
                                    {/* <TableCell className={this.props.classes.table_head}>Days</TableCell> */}
                                    <TableCell className={this.props.classes.table_head}></TableCell>
                                    <TableCell className={this.props.classes.table_head}>{translate('custom.entrances')}</TableCell>
                                </TableRow>
                            </TableBody>
                            <TableBody>
                                {row_data.map((r_data, r_idx) => (
                                    <TableRow
                                        key={r_idx}
                                    >
                                        <TableCell className={this.props.classes.table_numeric} numeric="true">{('daywise_entrances' in r_data) ? r_data["daywise_entrances"] : ''}</TableCell>
                                        <TableCell className={this.props.classes.table_row}>{('date' in r_data) ? r_data["date"] : ''}</TableCell>
                                        {/* <TableCell className={this.props.classes.table_numeric} numeric="true">{r_data["daywise_people"]}</TableCell> */}
                                        {/* <TableCell className={this.props.classes.table_row}>{translate('custom.day')} - {r_data["day"]}</TableCell> */}
                                        <TableCell className={this.props.classes.table_numeric} numeric="true">{'entrances' in r_data ? r_data["entrances"] : ''}</TableCell>
                                        <TableCell className={this.props.classes.table_row}>{('entrance_name' in r_data ? r_data["entrance_name"] : '') + ((('entrance_begin_date' in r_data) && r_data['entrance_begin_date']) ? ' - ' + r_data["entrance_begin_date"] : '')}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableBody className={this.props.classes.table_head} >
                                <TableRow>
                                    <TableCell className={this.props.classes.table_head_numeric} numeric="true">{formatNumber(total["daywise_entrances"])}</TableCell>
                                    <TableCell className={this.props.classes.table_head}>{translate('custom.total_daywise_entrances')}</TableCell>
                                    {/* <TableCell className={this.props.classes.table_head_numeric} numeric="true">{total["daywise_people"]}</TableCell> */}
                                    {/* <TableCell className={this.props.classes.table_head}>Total People</TableCell> */}
                                    <TableCell className={this.props.classes.table_head_numeric} numeric="true">{formatNumber(total["entrances"])}</TableCell>
                                    <TableCell className={this.props.classes.table_head}>{translate('custom.total_entrances')}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Box>
                </>
            );

            return (
                <div>
                    <div className="noPrint">
                        <div style={{maxWidth: 850, marginLeft: "auto", marginRight: "auto"}} id="entrance_count_chart">
                            {view}
                        </div>
                    </div>
                    <div className="print">
                        <div style={{maxWidth: 850, marginLeft: "auto", marginRight: "auto"}} id="entrance_count_chart_print">
                            {view}
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <>
                    <div className='print'>
                        <div style={{maxWidth: 850, marginLeft: "auto", marginRight: "auto", height: this.max_allowed_height + 75}}></div>
                    </div>
                </>
            )
        }
    }
}

const styles = theme => ({
    table_head: {
        backgroundColor: theme.palette.primary.main,
        fontWeight: "bold",
        fontSize: 15,
        color: theme.palette.primary.contrastText,
    },
    table_head_numeric: {
        textAlign: 'right',
        backgroundColor: theme.palette.primary.main,
        fontWeight: "bold",
        fontSize: 15,
        color: theme.palette.primary.contrastText
    },
    table_numeric: {
        textAlign: 'right',
        fontSize: 15
    },
    table_row: {
        fontSize: 15
    },
    titleTextStyle: {
        color: theme.palette.primary.main,
        fontSize: 24,
        bold: true,
        marginBottom: 15
    }
});

export default withStyles(styles)(EntranceCount);