import react from 'react';
import { Autocomplete, Box, Button, Grid, LinearProgress, Paper, TextField } from '@mui/material';
import { translate } from '../../i18n/customI18nProvider';
import { getEventSmartphones, putEventSmartphones, forcePutEventSmartphones, validateEventSmartphones } from '../../services/event.service';
import Swal from 'sweetalert2';
import { convertMessageCodeToMessage } from '../../util';
import NoLayout from './NoLayout';

class AddEditEventSmartphones extends react.Component {

    constructor(props) {
        super(props);
        this.state = {
            mode: null,
            device_id: null,
            title: translate('custom.smartphones'),
            loading: false,
            is_form_valid: true,
            form_error: {},
            // ------
            smartphones: [],
        }
    }

    async setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    async componentDidMount() {
        let mode = (this.props?.match?.params?.event_id || this.props?.event_id) ? 'edit' : 'add';
        let event_id = this.props?.match?.params?.event_id || this.props?.event_id;
        await this.setStateAsync({
            loading: true,
            mode,
            event_id
        });

        if (mode === 'edit')
            await this.getSmartphones();
        
        this.setState({ loading: false });
    }   

    async getSmartphones() {
        let event_smartphones = await getEventSmartphones(this.state.event_id);
        if (event_smartphones.error) {
            Swal.fire({
                icon: 'error',
                title: translate('custom.error'),
                text: event_smartphones.error_code ? convertMessageCodeToMessage(event_smartphones.error_code) : event_smartphones.error
            });
            return;
        }
        event_smartphones = event_smartphones?.event_smartphones;

        await this.setState({
            smartphones: event_smartphones?.smartphones ?? []
        });
    }

    async save(force=false) {

        console.log(this.state.smartphones);

        if (!this.isFormValid()) return;

        if (!force) {
            if (!(await this.validateForm())) return;
        }

        let payload = {
            smartphones: this.state.smartphones
        }

        let response;

        if (force) {
            response = await forcePutEventSmartphones(this.state.event_id, payload);
        } else {
            response = await putEventSmartphones(this.state.event_id, payload);
        }
        if (response.error) {
            Swal.fire({
                icon: 'error',
                title: translate('custom.error'),
                text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error
            });
            return;
        }

        Swal.fire({
            icon: 'success',
            title: translate('custom.success'),
            text: translate('custom.saved_successfully'),
            showConfirmButton: false,
            timer: 1500
        });


    }

    isFormValid(key=null) {

        let form_error = {};

        if (Object.keys(form_error).length > 0) {
            this.setState({ form_error, is_form_valid: false });
            return false;
        } else {
            this.setState({ form_error: {}, is_form_valid: true });
            return true;
        }

    }

    validateForm = async () => {
        
        let form_error = this.state.form_error;
    
    
        let payload = {
            smartphones: this.state.smartphones
        };

        let event_smartphones_validate = await validateEventSmartphones(this.state.event_id, payload);
    
        if (event_smartphones_validate?.smartphones) {
            if (event_smartphones_validate.smartphones?.length > 0) {
                form_error['smartphones'] = '';
                for (let i=0; i<event_smartphones_validate.smartphones.length; i++) {
                    if (form_error['smartphones'] != '') {
                        form_error['smartphones'] += ' & ';
                    }
                    form_error['smartphones'] += `${translate('custom.smartphone')} - ${event_smartphones_validate.smartphones[i].smartphone_number} ${translate('custom.is_already_taken_by')} ${event_smartphones_validate.smartphones[i].event_name}`;
                }
            }
        }

        this.setState({ form_error });

        if (Object.keys(form_error).length > 0) {
            return false;
        }

        return true;
    }



    render() {
        return (
            <NoLayout>

                {(this.state.loading) ? (
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                ) : null}

                <Paper sx={{mt: 2, padding: 2}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Autocomplete
                                freeSolo
                                multiple
                                value={this.state.smartphones}
                                onChange={async (e, newValue) => {
                                    let newValues = [];
                                    newValue.forEach((value) => {
                                        newValues = newValues.concat(value.split(/[\s,]+/));
                                    });
                                    await this.setStateAsync({ smartphones: newValues });
                                    this.validateForm();
                                }}
                                options={[]}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={translate('custom.smartphones')}
                                        error={this.state.form_error['smartphones'] ? true : false}
                                        helperText={this.state.form_error['smartphones']}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{mt: 2, justifyContent: 'space-around'}}>
                        <Grid item xs={12} md={6} lg={4} xl={3}>
                            <Button variant='contained' color='primary' fullWidth
                                onClick={async () => {
                                    await this.save();
                                }}
                            >{this.state.loading ? translate('custom.loading') : translate('custom.save')}</Button>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} xl={3}>
                            <Button variant='contained' color='primary' fullWidth
                                onClick={async () => {
                                    await this.save(true);
                                }}
                            >{this.state.loading ? translate('custom.loading') : translate('custom.force_save')}</Button>
                        </Grid>
                    </Grid>
                </Paper>
            </NoLayout>
        );
    }
}

export default AddEditEventSmartphones;
